import React, { useRef, useState, useEffect, useCallback } from "react";
import Layout from "@components/layout";
import { GatsbyImage } from "gatsby-plugin-image"

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import Animation modules
import { motion } from "framer-motion"

// Import SVG
import hatena_icon from '@images/hatena_icon.svg';
import ent_l_icon from '@images/entry_l_icon.svg';
import ent_r_icon from '@images/entry_r_icon.svg';
import topphone from '@images/phone-image.webp';
import topimage01 from '@images/top-image01_2.webp';
import topimage02 from '@images/top-image02.webp';
import topimage03 from '@images/top-image03.webp';
import toptokuchou01 from '@images/top_tokuchou01.webp';
import toptokuchou02 from '@images/top_tokuchou02.webp';
import toptokuchou03 from '@images/top_tokuchou03.webp';
import toptokuchou04 from '@images/top_tokuchou04.webp';
import toptokuchou05 from '@images/top_tokuchou05.webp';

import charange01 from '@images/step1_icon.svg';
import charange02 from '@images/step2_icon.svg';
import charange03 from '@images/step3_icon.svg';

// 関数呼び出し
import { FixedFoot } from "@components/footer.js";
import { SeoContents } from "@components/seo-data.js" // Seo情報設定
import { EntryClassSsdata } from "@components/entry-class-ssdata.js" // チャレンジコース情報

const PropIndex = () => {
  let fixedItem = FixedFoot( 'nomal' ); // 固定フッター
  
  const entryUrl = '/register/';
  // Seo情報設定
  const pathName = 'index';
  const seoTag = SeoContents({ Path:pathName });

  let setArray;
  let pushArray = [];
  const pickupPath = "/";
  const LinkHowto1 = "/howto/";
  const LinkAbout = "/about/";
  const LinkGuidance = "/guidance/";
  const LinkGuidance_sc = "/guidance#scale";
  const LinkPayment_pay = "/payment#pay";
  const LinkPayment = "/payment/";
  const LinkHowto02 = "/howto#how";
  
  // チャレンジコースタブ生成
  const [openTab, setOpenTab] = React.useState(1); // 取引資金タブ切り替え用useState
  const [currentDisplay, setCurrentDisplay] = useState(1); // チャレンジコース切り替え用useState（mobile）
  let tabItem;
  let productArray = ['¥1,000,000', '¥3,000,000', '¥5,000,000', '¥10,000,000', '¥20,000,000'] // タブの金額設定
  
  // tabList作成function
  function makeTabList ( productArray ) {
    let tabArray = [];
    for (let i = 0; i < productArray.length; i++) {
      // 調整　資金調達額のタブの設定
      tabItem = <>
        <span className="mr-2 last:mr-0 flex-auto text-center mb-3 md:mb-0 border-[solid]  rounded-md">
          <a className={" rounded-[4.8px] md:text-xl text-base font-bold uppercase px-5 py-3 block leading-normal " + (openTab === (i + 1) ? "bg-[#EFAA00] text-white" : "bg-white text-black-400")} // タブのCSS設定
            onClick={e=> {e.preventDefault(); setOpenTab((i + 1));}} data-toggle="tab" href={"#link" + String(i + 1)} role="tablist">
            {productArray[i]}
          </a>
        </span>
      </>
      tabArray.push(tabItem)
    }
    let tabSubList = tabArray.map((result) => {
      return <li className="flex-[0_0_calc(50%_-_10px)] md:flex-[auto] m-[5px] ">{result}</li>
    });
    let tabList = <><ul className="flex list-none flex-wrap pt-3 pb-4 flex-row  px-5" role="tablist">{tabSubList}</ul></>
    return tabList
  }
  const tabList = makeTabList ( productArray );

  // チャレンジコース切り替え用ボタン設定（mobile）
  // 右ボタン処理
  const handleClickR = () => {
    setCurrentDisplay(currentDisplay => {
      return currentDisplay === 3 ? 1 : currentDisplay + 1;
    });
  };
  // 左ボタン処理
  const handleClickL = () => {
    setCurrentDisplay(currentDisplay => {
      return currentDisplay === 1 ? 3 : currentDisplay - 1;
    });
  };
  // ボタンで切り替える変数設定
  let entryClassCss1, entryClassCss2, entryClassCss3, textCss1, textCss2, textCss3, entryName;
  switch(currentDisplay) {
    case 1:
      entryClassCss1 = "";
      entryClassCss2 = "hidden";
      entryClassCss3 = "hidden";
      textCss1 = "text-[#02478E]";
      textCss2 = "";
      textCss3 = "";
      entryName = "STEP1";
      break;
    case 2:
      entryClassCss1 = "hidden";
      entryClassCss2 = "";
      entryClassCss3 = "hidden";
      textCss1 = "";
      textCss2 = "text-[#02478E]";
      textCss3 = "";
      entryName = "STEP2";
      break;
    case 3:
      entryClassCss1 = "hidden";
      entryClassCss2 = "hidden";
      entryClassCss3 = "";
      textCss1 = "";
      textCss2 = "";
      textCss3 = "text-[#02478E]";
      entryName = "認定トレーダー昇格";
      break;
    default:
      entryClassCss1 = "";
      entryClassCss2 = "hidden";
      entryClassCss3 = "hidden";
      textCss1 = "text-[#02478E]";
      textCss2 = "";
      textCss3 = "";
      entryName = "STEP1";
      break;
  }

  // チャレンジコース生成
  let entryData = EntryClassSsdata(); // チャレンジコース情報読み込み
  let fieldArray = Object.keys(entryData[0].node); // スプシのキー値を配列化
  fieldArray = fieldArray.filter(field => field !== 'fundingAmount' && field !== 'dataNum'); // 不要なキー値を削除
  let paramArray = ['/?=001', '/?=002', '/?=003', '/?=004', '/?=005'] // エントリーするbuttonの遷移先
  let entryClassSsdata = []; // ５パターンの表を配列化
  for ( let i = 0; i < 5; i++ ) {
    setArray = fieldArray.map((result) => {
      // 調整　モーダル内のテキストの設定
      let item = <>
          <div className="min-h-[300px] md:min-h-[200px] break-words bg-white w-full rounded py-10 px-5 text-left">
            <div className="flex-auto">
              {entryData[1].node[result]}
            </div>
          </div>
        </>;
      let modalData = ModalItem(item)
      let modalCss1, modalCss2, modalCss3, modalCss4;
      if (result === 'entryClass' ) {
        modalCss1 = "!hidden";
        modalCss2 = "!bg-white text-[#02478E] !px-0 !justify-center";
        modalCss3 = "!bg-white text-[#02478E] !text-[16px] !px-0";
        modalCss4 = "!bg-white text-[#02478E] !text-[18px] !px-0";
      }
      // 調整　表・各行の設定
      return  <>
                {/* PC用リスト */}
                <tr className="hidden md:table-header-group">
                  <th className={"h-[120px] p-8 text-xs bg-white flex justify-between w-full " + modalCss2}><span className="flex items-center text-left">{entryData[0].node[result]}</span><div className={modalCss1}>{modalData}</div></th>{/* 1列目 */}
                  <th className={"h-[120px] p-8 text-xs  bg-[#E6F3FF] md:table-cell w-1/5 " + modalCss2}><span>{entryData[(i * 3) + 2].node[result]}</span></th>{/* 2列目 */}
                  <th className={"h-[120px] p-8 text-xs bg-white md:table-cell w-1/5 " + modalCss3}>{entryData[(i * 3) + 3].node[result]}</th>{/* 3列目 */}
                  <th className={"h-[120px] p-8 text-xs bg-[#E6F3FF] md:table-cell w-1/5 " + modalCss4}>{entryData[(i * 3) + 4].node[result]}</th>{/* 4列目 */}
                </tr>
                {/* MB用リスト */}
                <tr className="md:hidden">
                  <th className={"h-[120px] p-6 text-xs bg-white flex justify-between w-full " + modalCss1}><span className="flex items-center w-[100px]  text-left">{entryData[0].node[result]}</span><div className={modalCss1}>{modalData}</div></th>{/* 1列目 */}
                  <th className={"h-[120px] p-6 text-xs bg-[#E6F3FF] md:table-cell " + entryClassCss1 + " " + modalCss1}><span>{entryData[(i * 3) + 2].node[result]}</span></th>{/* 2列目 */}
                  <th className={"h-[120px] p-6 text-xs bg-[#E6F3FF] md:table-cell " + entryClassCss2 + " " + modalCss1}>{entryData[(i * 3) + 3].node[result]}</th>{/* 3列目 */}
                  <th className={"h-[120px] p-6 text-xs bg-[#E6F3FF] md:table-cell " + entryClassCss3 + " " + modalCss1}>{entryData[(i * 3) + 4].node[result]}</th>{/* 4列目 */}
                </tr>
              </>
    });
    // 調整　チャレンジコース表全体
    pushArray = <>
      <div className={(openTab === (i + 1) ? "block " : "hidden ") + "max-w-5xl  mx-auto px-5 pt-0  ent_font"}> 
        <table className="text-center flow-root md:table">
          {/* MB用追加(PC非表示) */}
          <tr className="test1 md:hidden flex justify-between bg-white">
            <button onClick={handleClickL} className="p-5">
              <img alt="" src={ent_l_icon}/> {/* 調整　左マークアイコンに差し替える */}
            </button>
            <div className="test2 text-[#02478E] font-bold py-6"><p className="text-xs">チャレンジコース</p><p className="text-[24px]">{entryName}</p></div> {/* 調整　テキストCSSを調整 */}
            <button onClick={handleClickR} className="p-5">
              <img alt="" src={ent_r_icon}/> {/* 調整　右マークアイコンに差し替える */}
            </button>
          </tr>
          <div className="test5 md:hidden flex justify-between bg-[#F6F6F6] px-10 py-5 text-xs font-bold text-[#B9B9B9]">          
            <p className={textCss1}>STEP1</p><p>＞</p><p className={textCss2}>STEP2</p><p>＞</p><p className={textCss3}>トレーダー</p>
          </div>
          {/* 共通データ */}
          {setArray}
          {/* MB用追加(PC非表示) */}
          {/* <tr>
            <th className={"p-8 text-xs flex justify-between bg-white text-left"}>{/* 1列目 
              <p>新規無料登録をする</p>  
            </th>
            <th className={"p-8 text-xs md:table-cell bg-[#E6F3FF]"} colSpan="3">{/* 2列目
              <a href={paramArray[i]} className="whitespace-nowrap p-2 rounded-full text-xs text-white bg-[#EFAA00] px-4">登録する</a> {/* 調整　エントリーするボタン 
            </th>
          </tr>*/}
        </table>
      </div>
    </>;
    entryClassSsdata.push(pushArray)
  }






  return (
  <>
     <Layout>
      {seoTag}
      <div class="parallax_box">
  <div class="parallax_content img_bg_01">
        <div className="px-5 max-w-xl mx-auto xl:max-w-5xl px-5 relative xl:h-[580px] ">          
          <div className="py-10 max-w-[800px] xl:absolute left-5 xl:top-[15%]">
            <h2 className="xl:text-5xl text-4xl font-black mb-5">日本発のプロップファーム</h2>
            <p className="xl:text-2xl text-xl leading-10 font-black">Just tradeの資金で
              <font className="text-[#1F55A6] text-3xl">最大4,000万円の取引が可能</font><br></br> あなたの投資損失を請け負い、<br className="md:hidden"></br>
              <font className="text-[#1F55A6] text-3xl">利益を最大90%還元</font>します
            </p>
            <div className="flex justify-around block xl:hidden mt-10">
              <div className="max-w-[224px] ml-[-8%] md:mt-[5%] mt-[10%]">
                <p> <img className="h-auto w-full" alt="" src={topphone}/></p>
              </div>
              <div className="xl:hidden block max-w-[200px] w-full mx-auto text-[14px] font-black">
                <div className="bg-[#1F55A6] rounded-[10px] py-3 px-3 text-center w-full text-white leading-5 mb-5">取引に<br></br> 自己資金不要</div>
                <div className="bg-[#1F55A6] rounded-[10px] py-3 px-3 text-center w-full text-white leading-5 mb-5">損失責任なし<br></br>追証なし！</div>
                <div className="bg-[#1F55A6] rounded-[10px] py-3 px-3 text-center w-full text-white leading-5 mb-5">1回限りの<br></br>チャレンジ費用</div>
                <div className="bg-[#1F55A6] rounded-[10px] py-3 px-3 text-center w-full text-white leading-5 mb-15 xl:max-h-[134px]">
                  <font className="text-[10px] leading-4">チャレンジクリア後に</font><br></br>チャレンジ費用と同額の報奨金授与
                </div>
              </div>
            </div>
            <div className="flex xl:justify-start justify-center xl:mt-10 mt-4">
              <a href={entryUrl} className="duration-300 rounded-full block xl:mx-0 mx-auto max-w-xs text-center text-base text-white font-black py-5 px-16 my-2 bg-[#EFAA00]  hover:tracking-widest">新規無料登録はこちら</a>
             
            </div>
            <p className="mt-8 xl:text-left text-center link_under"><a href={LinkHowto02} className="text-[#1F55A6] font-bold ">登録からチャレンジ申請までの<br class="res_br"></br>流れはこちら＞</a></p>
          </div>
          <div className="max-w-[450px] absolute right-[-5%] hidden xl:block">
            <p> <img className="mb-5 mx-auto h-auto" alt="" src={topphone}/></p>
          </div>
        </div>
        </div>
        </div>
        <div className="bg-white xl:pb-[70px] pb-2 ">
        <div className="xl:flex hidden px-5 max-w-5xl mx-auto justify-between text-[26px] font-black flex-wrap ">
          <div className="-mt-[30px] bg-[#1F55A6] rounded-[10px] py-10 px-5 text-center pt-[3%] xl:w-[23%] text-white leading-8 mb-5">損失責任ゼロ<br></br>追証なし！</div>
          <div className="-mt-[30px] bg-[#1F55A6] rounded-[10px] py-10 px-5 text-center pt-[3%] xl:w-[23%] text-white leading-8 mb-5">取引に<br></br> 自己資金不要</div>
          <div className="-mt-[30px] bg-[#1F55A6] rounded-[10px] py-10 px-5 text-center pt-[3%] xl:w-[23%] text-white leading-8 mb-5">1回限りの<br></br>チャレンジ費用</div>
          <div className="-mt-[30px] bg-[#1F55A6] rounded-[10px] py-2 pt-[3%] px-5 text-center xl:w-[23%] text-white leading-8 mb-15 xl:max-h-[134px]"> <font className="text-[12px] leading-4">人気のプラットフォーム</font><br></br>MT5を採用</div>
        </div>

        <div className="px-5 max-w-xl mx-auto xl:max-w-5xl relative xl:h-[420px] mt-[70px] xl:overflow-visible overflow-hidden">
          <h2 className="xl:text-5xl text-4xl font-black mb-5 text-center">Just tradeとは？</h2>

      
          <div className="py-10 max-w-[500px] xl:absolute xl:left-5 xl:top-[25%]">
            <h3 className="xl:text-2xl text-xl mb-3 font-bold">海外で人気のプロップファーム</h3>
            <p className="xl:text-xl text-lg xl:leading-10 leading-7 font-black">Just tradeは日本発のプロップファームです。<br></br>
２段階の審査基準をクリアした<span className="text-[#DF5F6A]">認定トレーダー</span>に弊社の資金を使い<br className="md:hidden"></br><span className="text-[#DF5F6A] text-3xl">リスクなしで最大90%</span>の利益を得るチャンスを与えます。</p>
          </div>
          <div className="max-w-[650px] xl:absolute xl:right-[-15%]">
            <motion.div className=" mx-auto text-center xl:block xl:mt-[0px] xl:ml-[40px]" initial={{ opacity:0 ,x:100 }} whileInView={{ opacity:1 ,x:0 }} transition={{ duration: 1 }} viewport={{ once: true }}>
              <img className="mb-5 mx-auto h-auto" alt="" src={topimage01}/>
            </motion.div>
          </div>
        </div>

        <div className="hidden px-5 xl:flex justify-center  max-w-5xl mx-auto">
          <a href={entryUrl} className="duration-300 rounded-full block xl:mx-0 mx-auto  max-w-xs text-center text-base text-white font-black py-5 px-16 my-2 bg-[#EFAA00]   hover:tracking-widest">新規登録はこちら</a> 
          <a href={LinkAbout} className="duration-300 rounded-full block xl:mx-8 mx-auto max-w-[400px] text-center text-base text-[#1F55A6] font-black py-5 px-16 my-2 bg-[#F6F6F6] border-[#1F55A6] border-[1px]   hover:tracking-widest">プロップファームとは？</a> 
        </div>
      </div>
      <div className="triangle xl:hidden block "></div>

      <div class="parallax_box">
  <div class="parallax_content img_bg_01">

 {/* 特徴 */}

 <div className="px-5 max-w-xl mx-auto xl:max-w-5xl pt-[70px]">
        <h2 className="xl:text-5xl text-4xl font-black xl:mb-[100px] mb-12 text-center pt-5">どんな特徴が<br className="res_br"></br>あるの？</h2>
        <div className="text-center xl:mb-[70px] mb-10">
        <motion.div initial={{ opacity:0 ,y:-50 }} whileInView={{ opacity:1 ,y:0 }} transition={{ duration: 1 }} viewport={{ once: true }}> 
   <div className="max-w-[400px] mx-auto relative h-[160px]">
    
            <p className="absolute z-20 left-1/2 xl:top-[-6%] top-[15%] xl:max-w-[380px] max-w-[220px] xl:ml-[-180px] ml-[-110px]">
              <img className="mb-5 mx-auto h-auto" alt="" src={toptokuchou01}/>
            </p>
            <p className="absolute rounded-full h-[120px] w-[120px] bg-[#fff] left-[50%] ml-[-60px] pt-[80px] top-[20px] font-bold text-[#1F55A6]">Point1</p>
          </div>
            <h3 className="text-2xl font-semibold mb-5"><span className="text-sm">チャレンジ費用のみで</span><br></br><span className="text-[#DF5F6A] text-3xl">最大4,000万円</span>の取引可能</h3>
            </motion.div>
            <p>例えば、30,000円のチャレンジ費用で<br></br>300万円の資金を25倍のレバレッジで運用できます</p>
      </div>


        <div className="text-center xl:mb-[70px] mb-10">
        <motion.div initial={{ opacity:0 ,y:-50 }} whileInView={{ opacity:1 ,y:0 }} transition={{ duration: 1 }} viewport={{ once: true }}> 
          <div className="max-w-[400px] mx-auto relative h-[200px]">
            <p className="absolute z-20 left-1/2 xl:top-[5%] top-[18%] xl:max-w-[300px] max-w-[120px] xl:ml-[-75px] ml-[-60px]">
              <img className="mb-5 mx-auto h-auto" alt="" src={toptokuchou02}/>
            </p>
            <p className="absolute rounded-full h-[120px] w-[120px] bg-[#fff] left-[50%] ml-[-60px] pt-[80px] top-[60px] font-bold text-[#1F55A6]">Point2</p>
          </div>
            <h3 className="text-2xl font-semibold mb-5">取引に自己資金不要</h3>
            </motion.div>
          <p>トレードする際にはトレーダーの自己資金は一切不要です。</p>

        </div>

        <div className="text-center xl:mb-[70px] mb-10">
        <motion.div initial={{ opacity:0 ,y:-50 }} whileInView={{ opacity:1 ,y:0 }} transition={{ duration: 1 }} viewport={{ once: true }}> 
          <div className="max-w-[400px] mx-auto relative h-[200px]">
            <p className="absolute z-20 left-1/2 xl:top-[0%] top-[15%] xl:max-w-[300px] max-w-[90px] xl:ml-[-70px] ml-[-45px]">
              <img className="mb-5 mx-auto h-auto" alt="" src={toptokuchou03}/>
            </p>
            <p className="absolute rounded-full h-[120px] w-[120px] bg-[#fff] left-[50%] ml-[-60px] pt-[80px] top-[60px] font-bold text-[#1F55A6]">Point3</p>
          </div>
            <h3 className="text-2xl font-semibold mb-5">損失責任なし</h3>
          </motion.div>
          <p>取引で出た損失は全てJust tradeが負担します。
          </p>
        </div>

        <div className="text-center xl:mb-[70px] mb-10">
        <motion.div initial={{ opacity:0 ,y:-50 }} whileInView={{ opacity:1 ,y:0 }} transition={{ duration: 1 }} viewport={{ once: true }}> 
          <div className="max-w-[400px] mx-auto relative h-[200px]">
            <p className="absolute z-20 left-1/2 xl:top-[-6%] top-[15%] xl:max-w-[350px] max-w-[120px] xl:ml-[-150px] ml-[-60px]">
              <img className="mb-5 mx-auto h-auto" alt="" src={toptokuchou04}/>
            </p>
            <p className="absolute rounded-full h-[120px] w-[120px] bg-[#fff] left-[50%] ml-[-60px] pt-[80px] top-[50px] font-bold text-[#1F55A6]">Point4</p>
          </div>
            <h3 className="text-2xl font-semibold  mb-5">利益の最大90%を<br></br>トレーダーに還元</h3>
          </motion.div>
          <p>Just tradeの資金を使いノーリスクで取引できるだけでなく<br></br>
スケーリングプランの条件をクリアすると利益の最大90％を還元します。
          </p>
        </div>

        <div className="text-center xl:pb-[120px] pb-[40px] ">
        <motion.div initial={{ opacity:0 ,y:-50 }} whileInView={{ opacity:1 ,y:0 }} transition={{ duration: 1 }} viewport={{ once: true }}> 
          <div className="max-w-[400px] mx-auto relative h-[230px]">
            <p className="absolute z-20 left-1/2 xl:top-[-8%] top-[24%] xl:max-w-[330px] max-w-[90px] xl:ml-[-85px] ml-[-45px]">
              <img className="mb-5 mx-auto h-auto" alt="" src={toptokuchou05}/>
            </p>
            <p className="absolute rounded-full h-[120px] w-[120px] bg-[#fff] left-[50%] ml-[-60px] pt-[80px] top-[80px] font-bold text-[#1F55A6]">Point5</p>
          </div>
            <h3 className="text-2xl font-semibold mb-5">チャレンジ費用の同額が、<br></br>認定トレーダーに昇格後に受け取れます</h3>
          </motion.div>
          <p>認定トレーダーに昇格後、1回目の成功報酬支払い時に<br></br>
             チャレンジ費用と同額の報奨金が取引資金に加算されます
          </p>
        </div>
      </div>

  </div>


</div>



      {/* チャレンジ期間・表 */}
      <div className="bg-[#F6F6F6] xl:hidden block ">
      <div className="triangle02 "></div>
      </div>
      <div className="bg-[#fff] pb-[70px] py-12 ">
        <h2 className="xl:text-5xl text-4xl font-black xl:mb-[70px] mb-[50px] mt-10 text-center leading-[1.5!important] px-5">認定トレーダーに<br className="res_br"></br>なるには</h2>
        <div className="max-w-5xl mx-auto px-5 xl:block hidden md:mb-[50px]">
          <table>
            <tr>
            <td colSpan={2} className="pl-5 pt-5 w-2/3  align-top  border-t-[8px] border-r-[8px] border-l-[8px] border-[#F6F6F6]" ><p className="text-2xl text-center text-[#1F55A6] font-bold ">チャレンジ期間</p></td>
            <td className="pl-5 pt-5 w-1/3  align-top  " ><p className="text-2xl text-center text-[#1F55A6] font-bold ">認定トレーダーに昇格</p></td>
            </tr>
            <tr>
            <td className="relative w-1/3  p-[20px]  align-top border-b-[8px] border-l-[8px] border-[#F6F6F6]">
            <div className="rounded-md bg-[#F6F6F6]  p-5 "><p className="text-xl font-bold mb-3 text-center text-[#1F55A6] "><img className="mb-4 mx-auto max-w-[90px]" src={charange01}></img></p>
            <p className="text-base min-h-[170px]">STEP1では<span className="font-bold underline text-[#DF5F6A] ">30日間</span>という取引期間のなかで目標利益を目指していただきます。<br></br><br></br>指定された最低取引回数や最大損失などの条件をクリアできれば次のSTEPに進むことができます。</p>
            </div>
            <p className="before:top-[40%] before:right-[-3%] before:absolute before:content-[url(@images/2step_yajirushi.svg)] before:text-black before:text-base before:z-50"></p>
            </td>
            <td className="relative w-1/3  p-[20px] pr-[20px] align-top border-b-[8px] border-r-[8px] border-[#F6F6F6]"><div className="rounded-md bg-[#F6F6F6] p-5"><p className="text-xl font-bold  mb-3 text-center text-[#1F55A6] "><img src={charange02} className="mb-4 mx-auto max-w-[90px]"></img></p>
            <p className="text-base  min-h-[170px]">こちらでは更に取引期間を延ばし、<span className="font-bold underline text-[#DF5F6A] ">60日間</span>で目標利益を目指していただきます。<br></br><br></br>指定の条件を達成するとJust trade「<span className="text-[#DF5F6A] font-bold">認定トレーダー</span>」に昇格できます。</p></div>
            <p className="before:top-[40%] before:right-[-3%] before:absolute before:content-[url(@images/2step_yajirushi.svg)] before:text-black before:text-base before:z-50"></p>
            </td>
            <td className="w-1/3  pl-[20px] pt-5 align-top"><div className="rounded-md bg-[#1F55A6] text-white p-5">
            <p><img src={charange03} className="mb-4 mx-auto mb-2 mx-auto max-w-[90px]"></img></p>
             
            <p className="text-bases">「認定トレーダー」に昇格後は、取引で得た利益の<span className="font-bold underline text-[#FFE4A0] ">80%</span>を受け取ることができます。<br></br><br></br>また、スケーリングプランの条件をクリアすると利益の還元率を<br></br><span className="font-bold underline text-[#FFE4A0] ">最大90%</span>まで増やすことができます。
             </p></div></td>
            </tr>
          </table>
        </div>
        

        <div className="max-w-5xl mx-auto px-5 xl:block xl:hidden block">
          <table>
            <tr>
            <td className="align-top p-5 relative pb-[50px] border-t-[8px] border-r-[8px] border-l-[8px] border-[#F6F6F6]"><p className="text-xl text-center text-[#1F55A6] font-bold mb-5">チャレンジ期間</p>
          <div className="rounded-md bg-[#F6F6F6] p-5"><p className="text-xl font-bold mb-3 text-center text-[#1F55A6] "><img className="mb-4 mx-auto max-w-[90px]" src={charange01}></img></p>
            <p className="text-base">STEP1では<span className="font-bold underline text-[#DF5F6A] ">30日間</span>という取引期間のなかで目標利益を目指していただきます。<br></br><br></br>指定された最低取引回数や最大損失などの条件をクリアできれば次のSTEPに進むことができます。</p>
            <p className="before:z-[800] before:bottom-[-2%] before:right-[45%] before:absolute before:content-[url(@images/2step_yajirushi-sp.svg)] before:text-black before:text-base"></p>
            </div></td>
            </tr>
            <tr>
         
            <td className="align-top p-5 relative pb-[50px] border-b-[8px] border-r-[8px] border-l-[8px] border-[#F6F6F6]">
              <div className="rounded-md bg-[#F6F6F6] p-5"><p className="text-xl font-bold  text-[#1F55A6] mb-3 text-center"><img className="mb-4 mx-auto max-w-[90px]" src={charange02}></img></p>
            <p className="text-base">こちらでは更に取引期間を延ばし、<span className="font-bold underline text-[#DF5F6A] ">60日間</span>で目標利益を目指していただきます。<br></br><br></br>指定の条件を達成するとJust trade「<span className="text-[#DF5F6A] font-bold">認定トレーダー</span>」に昇格できます。</p>
            <p className=" before:bottom-[-5%] before:right-[45%] before:absolute before:content-[url(@images/2step_yajirushi-sp.svg)] before:text-black before:text-base"></p></div></td>
            </tr>
            <tr>
           
            <td className="align-top p-5 pt-10 relative">
            <p className="text-2xl text-center text-[#1F55A6] font-bold mb-5">認定トレーダーに昇格</p>
            <div className="rounded-md bg-[#1F55A6] text-white p-5 ">
            <p><img src={charange03} className="mb-4 mx-auto mb-2 mx-auto max-w-[90px]"></img></p>
            <p className="text-base">「認定トレーダー」に昇格後は、取引で得た利益の80%を受け取ることができます。<br></br><br></br>また、スケーリングプランの条件をクリアすると利益の還元率を最大90%まで増やすことができます。</p>
            </div></td>
            </tr>
          </table>
        </div>  

        <div className="hidden px-5 xl:flex justify-center  max-w-5xl mx-auto mt-[10px]">
          <a href={entryUrl} className=" duration-300 rounded-full block xl:mx-4 mx-auto  max-w-xs text-center text-base text-white font-black py-5 px-16 my-2 bg-[#EFAA00]   hover:tracking-widest">新規登録はこちら</a> 
          <a href={LinkGuidance} className="max-w-[400px] duration-300 rounded-full block xl:mx-4 mx-auto max-w-xs text-center text-base text-[#1F55A6] font-black py-5 px-16 my-2 bg-[#F6F6F6] border-[#1F55A6] border-[1px]   hover:tracking-widest">サービスのご案内はこちら</a> 
        </div>
        <a href={LinkGuidance} className="xl:hidden block mx-auto duration-300 rounded-full block md:mt-[70px] max-w-xs text-center text-base text-[#1F55A6] font-black py-5 md:px-16 px-5  bg-[#F6F6F6] border-[#1F55A6] border-[1px]  hover:tracking-widest">サービスのご案内はこちら</a>
      </div>

      {/* チャレンジコース・表 */}
      <div className="bg-[#02478E] py-20">
          <h2 className="xl:text-5xl text-4xl  text-white font-black md:mb-[40px] mb-7 md:mt-[10px] mt-0 text-center leading-[1.2] px-5">5タイプの<br className="res_br"></br>チャレンジコース</h2>
          <div className="text-center  text-2xl text-white">
            <p>取引金額によって変わる条件</p>
          </div>
          <div className="max-w-xl mx-auto md:max-w-5xl mt-[20px] ent_font">
            {tabList}
            {entryClassSsdata}
          </div>
          <div className="hidden px-5 md:flex justify-center  max-w-5xl mx-auto mt-[70px] mb-3">
            <a href={entryUrl} className="duration-300 rounded-full block xl:mx-4 mx-auto  max-w-xs text-center text-base text-white font-black py-5 px-16 my-2 bg-[#EFAA00]   hover:tracking-widest">新規登録はこちら</a> 
            <a href={LinkGuidance} className="max-w-[400px] duration-300 rounded-full block xl:mx-4 mx-auto max-w-xs text-center text-base text-[#1F55A6] font-black py-5 px-16 my-2 bg-white border-[#1F55A6] border-[1px]   hover:tracking-widest">サービスのご案内はこちら</a> 
          </div>
          <a href={LinkGuidance} className="md:hidden block  mx-auto duration-300 rounded-full block mt-12 max-w-xs text-center text-base text-[#1F55A6] font-black py-5 md:px-16 px-5  bg-white border-[#1F55A6] border-[1px]  hover:tracking-widest">サービスのご案内はこちら</a>
       
      </div>
      {/* 特徴 */}
      <div className="max-w-xl mx-auto md:max-w-5xl px-5 relative mt-[50px] ">
        <h2 className="md:text-6xl text-4xl  font-black  mb-[70px] text-center pt-10">魅力的な<br className="res_br"></br>チャンレンジ環境</h2>
        <div className="flex md:justify-around justify-center flex-wrap xl:mb-[100px] mb-[70px]">
          <div className="max-w-[350px]  xl:max-w-[320px] mb-10 xl:mb-3 bg-[#F6F6F6] p-10 rounded-xl">
            <h4 className="mb-5 text-xl text-center text-[#1F55A6] font-semibold">チャレンジクリア後に<br></br>報奨金授与</h4>
            <p className="text-xs leading-6 font-black">認定トレーダーに昇格後、1回目の<br></br>
             成功報酬の支払い時にチャレンジ費用と同額が報奨金として取引資金に加算されます。
            </p>
          </div>
          <div className="max-w-[350px]  xl:max-w-[320px]  mb-10 xl:mb-3  bg-[#F6F6F6] p-10 rounded-xl">
            <h4 className="mb-5 text-2xl text-center text-[#1F55A6] font-semibold">レバッジ最大25倍</h4>
            <p className="text-xs leading-6 font-black">全てのチャレンジコースでレバレッジ最大25倍で取引いただけます。<br></br>レバレッジをかけることでより大きなリターンを狙えます。</p>
          </div>
          <div className=" max-w-[350px]  xl:max-w-[320px]  mb-10 xl:mb-3  bg-[#F6F6F6] p-10 rounded-xl">
            <h4 className="mb-5 text-2xl text-center text-[#1F55A6] font-semibold">無料トライアル</h4>
            <p className="text-xs leading-6 font-black">チャレンジ費用を支払わずとも、何度でも無料で試せるデモトレードモードを用意しております。</p>
          </div>
          <div className=" max-w-[350px]  xl:max-w-[320px]  mb-10 xl:mb-3  bg-[#F6F6F6] p-10 rounded-xl">
            <h4 className="mb-5 text-2xl text-center text-[#1F55A6] font-semibold">人気のある<br></br>プラットフォーム</h4>
            <p className="text-xs leading-6 font-black">人気のプラットフォーム、MT5を採用。Windows版やMac版、モバイル版など、環境にあったMT5をマイページからいつでも無料でダウンロード可能です。</p>
          </div>
          <div className=" max-w-[350px]  xl:max-w-[320px]  mb-10 xl:mb-3  bg-[#F6F6F6] p-10 rounded-xl">
            <h4 className="mb-5 text-2xl text-center text-[#1F55A6] font-semibold">様々な<br></br>取引ツールに対応</h4>
            <p className="text-xs leading-6 font-black">EA（自動売買システム）のみならず、PAMMやMAMなどの取引にも対応しております。</p>
          </div>
          <div className=" max-w-[350px]  xl:max-w-[320px]  mb-10 xl:mb-3  bg-[#F6F6F6] p-10 rounded-xl">
            <h4 className="mb-5 text-2xl text-center text-[#1F55A6] font-semibold">自社の取引分析ツール</h4>
            <p className="text-xs leading-6 font-black">Just tradeが独自で開発した分析ツールを見ながら日々のトレードをレベルアップできます。</p>
          </div>
        </div>    
      </div>

      {/*スケーリングプラン*/}
      <div className="bg-[#F6F6F6] pt-[90px] pb-[80px]">
        <div className="max-w-xl mx-auto xl:max-w-5xl px-5 xl:relative xl:h-[420px] xl:overflow-visible overflow-hidden">
          <h2 className="xl:text-5xl text-4xl  font-black text-left pt-5">スケーリングプラン</h2>
          <div className="py-5 max-w-[800px] xl:absolute xl:left-5 xl:top-[25%]">
            <div className="xl:text-base text-base xl:leading-6 font-black max-w-[520px] ">
              <p>認定トレーダーに昇格後、2ヶ月以上利益を出し続け、総利益が10%以上維持できた場合にスケーリングプランが適用されます。<br></br><br></br>
                 取引資金の30％が新たに付与され、<span className="text-[#DF5F6A]">最大4,000万円</span>の取引が可能となります。<br></br><br></br>
                 更に利益の還元率も<span className="text-[#DF5F6A]">通常80%から90%</span>まで増やすことができます。
              </p>
              </div>
            <div className="xl:hidden block max-w-[650px]">
              <motion.div className="mx-auto text-center xl:mt-[-70px] xl:ml-[40px] mt-10" initial={{ opacity:0 ,x:100 }} whileInView={{ opacity:1 ,x:0 }} transition={{ duration: 1 }} viewport={{ once: true }}>
                <img className="mb-5 mx-auto h-auto" alt="" src={topimage02}/>
              </motion.div>
            </div>
            <a href={LinkGuidance_sc} className="xl:mx-0 mx-auto duration-300 rounded-full block mt-10 max-w-xs text-center text-base text-[#1F55A6] font-black py-5 px-14 my-2 bg-[#F6F6F6] border-[#1F55A6] border-[1px]  hover:tracking-widest">スケーリングプラン例</a>
          </div>
          <div className="hidden xl:block max-w-[650px] xl:absolute right-[-15%]">
            <motion.div className=" mx-auto text-center xl:mt-[-20px] xl:ml-[40px]" initial={{ opacity:0 ,x:100 }} whileInView={{ opacity:1 ,x:0 }} transition={{ duration: 1 }} viewport={{ once: true }}>
              <img className="mb-5 mx-auto h-auto" alt="" src={topimage02}/>
            </motion.div>
          </div>
        </div>
      </div>

      <div className="xl:mb-12 mb-1 max-w-xl mx-auto xl:max-w-5xl px-5 xl:relative xl:h-[420px] mt-[50px] xl:overflow-visible overflow-hidden">
        <h2 className="xl:text-5xl text-4xl  font-black mb-5 md:text-left text-center pt-5">スムーズな<br className="res_br"></br>出金システム</h2>
        <div className="py-5 max-w-[800px] xl:absolute xl:left-5 top-[25%]">
          <p className="xl:text-base text-base xl:leading-6 font-black max-w-[520px]">
          Just tradeではお客様に安心して取引していただけるよう<br></br>
          出金までの日数を最小限に抑えております。<br></br><br></br>
          また、資金が実際に着金するまでの出金状況も把握できるような<br></br>
          独自のツールもご用意しております。
          </p>
          <div className="xl:hidden block  max-w-[650px] mt-10">
            <motion.div className="mx-auto text-center xl:mt-[-70px] xl:ml-[40px]" initial={{ opacity:0 ,x:100 }} whileInView={{ opacity:1 ,x:0 }} transition={{ duration: 1 }} viewport={{ once: true }}>
              <img className="mb-5 mx-auto h-auto" alt="" src={topimage03}/>
            </motion.div>
          </div>
            <a href={LinkPayment_pay} className="mx-auto xl:mx-0 duration-300 rounded-full block mt-10 max-w-[350px] text-center text-base text-[#1F55A6] font-black py-5 px-16 my-2 bg-white border-[#1F55A6] border-[1px]  hover:tracking-widest">支払いについてはこちら</a> 
          </div>
          <div className="hidden xl:block max-w-[650px] absolute right-[-15%]">
            <motion.div className="mx-auto text-center xl:mt-[-70px] xl:ml-[40px]" initial={{ opacity:0 ,x:100 }} whileInView={{ opacity:1 ,x:0 }} transition={{ duration: 1 }} viewport={{ once: true }}>
              <img className="mb-5 mx-auto h-auto" alt="" src={topimage03}/>
            </motion.div>
          </div>
        </div>

        {/* 固定フッター */}
        {fixedItem}
      </Layout>
  </>
  )
}
export default PropIndex

// モーダル用funciton
export function ModalItem( item ) {

  // (※変更不要)モーダル用設定
  const[isModalOpen,setIsModalOpen]=useState(false)
  const closeModal= useCallback(() =>{
    setIsModalOpen(false)
    document.removeEventListener('click',closeModal)
  },[])
  useEffect(()=>{
    return ()=>{
      document.removeEventListener('click',closeModal)
    }
  },[closeModal])
  function openModal(event){
    setIsModalOpen(true)
    document.addEventListener('click',closeModal)
    event.stopPropagation()
  }

  // 子コンポーネント（モーダル）
  function Modal(props){
    const startYOffset = React.useRef(UseScrollPosition())
    startYOffset.current = window.pageYOffset // スクロール位置の左上のY軸座標
    // 調整　モーダル中身（テキスト以外）の設定
    return(
      <div id="modal" className="modal !z-20 text-black w-full text-right" onClick={(event)=>{event.stopPropagation()}}>
        {/* ここでモーダル表示位置を調整 */}
        <div className="absolute left-1/2 w-full mt-[39%] md:mt-[13%] max-w-[320px] md:max-w-[400px]  -ml-[160px] md:-ml-[200px] touch-none" style={{top: startYOffset.current}}> 
          {/* 出力データ */}
          <div className="!text-base absolute w-full bg-white z-20 max-w-[326px] md:max-w-[400px] font-normal">{item}</div>
          {/* この中のエリア押すと閉じる、閉じるボタン用*/}
          <button onClick={props.onClick} className="w-[30px] h-[30px] bg-black top-0 z-20 relative">
            <div className="!text-base text-white">×</div>
          </button>
        </div>
        {/*この中のエリア押すと閉じる、透過背景用 */}
        <div className="">
          <button onClick={props.onClick} className="w-full bg-gray-700 absolute top-[0px] left-0 h-[8030px] z-10 opacity-50 touch-none"></button>
        </div>
      </div>
    )
  }
  // 親コンポーネント（ボタン）
  return (
    <>
      <div>
        <button onClick={(event)=>{openModal(event)}} className=""> 
          <span className={"" + (isModalOpen? " hidden" :"")}>
            <img alt="" src={hatena_icon}/> {/* 調整　？マークアイコンに差し替える */}
          </span>
          {isModalOpen? <Modal onClick={(event)=>{closeModal(event)}} /> :""}
        </button>
      </div>
    </>
  );
}

// (※変更不要)モーダルスクロール位置取得用function
function UseScrollPosition() {
  const isProcessing = useRef(false)
  const [positionY, setPositionY] = useState(0)
  useEffect(() => {
    const handler = () => {
      if (isProcessing.current) return
      isProcessing.current = true
      window.requestAnimationFrame(() => { // Window.requestAnimationFrame()でpositionYステートの更新を間引く
        isProcessing.current = false
        setPositionY(window.scrollY)
      })
    }
    document.addEventListener('scroll', handler, { passive: true }) // スクロールイベントの登録
    return () => { // スクロールイベントの解除
      document.removeEventListener('scroll', handler)
    }
  }, [])
  return positionY // 取得したスクロール量をreturn
}



