// import React from "react"
import { useStaticQuery, graphql } from "gatsby";

export function EntryClassSsdata () {
  // Ssで項目を追加したらqueryを追加
  const data = useStaticQuery(graphql`
  query PostEntryClassQuery {
    allGoogleSpreadsheetSsdatabase3(sort: {fields: dataNum}) {
      edges {
        node {
          fundingAmount
          dataNum
          entryClass
          oneTimeFee
          maximumDailyLoss
          maximumTotalLoss
          tradingPeriod
          minimumTradingDays
          tradingLeverage
          profitTarget
          returnRate
        }
      }
    }
  }
  `
  )
  const posts = data.allGoogleSpreadsheetSsdatabase3.edges; // 全スプレッドシート情報を設定
  return posts
}